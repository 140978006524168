.float-right {
  float: right;
}

.sticky-top-tickets {
  position: sticky;
  top: 70px;
  z-index: 99;
}

.calendar-icon-datepicker {
  z-index: 10;
}

.timeGroupButton button:not(:first-child) {
  margin-left: 8px;
}

[data-bs-theme='dark'] .autocomplete-input input:focus {
  min-height: 44px;
  margin-right: -33px;
  background: var(--bs-gray-200);
  border-radius: 6px;
}

.react-datepicker-wrapper {
  width: 100%;
}
