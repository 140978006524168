/* .item must be first */
.item-pagination {
  align-items: center;
  color: #808e9b;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.item-pagination a {
  width: 100%;
  text-align: center;
}

.disabled-page-pagination {
  /*color: #808e9b;*/
}

.active-pagination {
  border: solid 1px #808e9b;
  border-radius: 40px;
  color: #808e9b;
  background-color: #0fbcf9;
}

.break-me-pagination {
}

.pagination-pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
}

.pagination-page-pagination {
  font-weight: 700;
}
