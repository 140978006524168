.overlay,
.content {
  z-index: 8888;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000046;
}

.content {
  position: fixed;
  background-color: #ffffff;

  border-radius: 10px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
}

.inpits {
  margin-bottom: 25px;
}

.buttons {
  width: 250px;
  display: flex;
  justify-content: space-between;
}